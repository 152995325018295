#rtl {
    .section-delivered {
        .container {
            &:after {
                // right: 16px;
                right: auto;
                left: 16px;

                transform: scale(-1, 1);

                @media (max-width: $tabletVMax) {
                    // right: 15px;
                    right: auto;
                    left: 15px;
                }
            }
        }

        .row {
            @media (max-width: $mobileResponsiveMax) {
                // flex-direction: column-reverse;
            }
        }

        .img-block {

            @media (min-width: $tabletH) {
                // padding-right: 20px;
                padding-right: 0px;
                padding-left: 20px;
            }

            @media (min-width: $tabletH) {
                // background-position: 30% 50%;
                background-position: 70% 50%;
            }
            
            @media (max-width: $tabletVMax) {
                // background-position: 50% 60%;
            }

            @media (max-width: $mobileResponsiveMax) {
                // background-position: 30% 60%;
                background-position: 70% 60%;
            }
        }

        .text-block {

            @media (min-width: $tabletH) {
                // margin-left: -30px;
                margin-left: 0px;
                margin-right: -30px;
            }
        }
    }
}