#rtl {
    @media (max-width: $mobileResponsiveMax) {
        .navbar-header{
            .navbar-toggle {

                // float: right;
                // margin-right: 15px;

                float: left;
                margin-right: 0;
                margin-left: 15px;

                transform: scale(-1, 1);

                &,
                &:hover {
                    &:after {
                        // right: 0;
                        left: 0;
                    }
                }
            }

            & + .navbar-collapse {
                border: none;

                .navbar-nav{
                    padding-right: 0;
                    
                    & > li {
                        // margin-left: 0;
                        margin-right: 0;
                        float: none;
                    }
                }
            }
        }
    }
}