
.section-contact-us {
    padding-top: 110px;
    padding-bottom: 374px;
    background-color: $color-theme-bg;

    @media (max-width: $tabletVMax) {
        padding-top: 68px;
        // padding-bottom: 182px;
        // padding-bottom: 284px;
        padding-bottom: 335px;
    }

    .container {

        @media (min-width: $desktop) {
            padding-left: 30px;
            width: 1025px;
        }
        
    }
    .img-block {
        text-align: center;
        margin-bottom: 72px;

        @media (max-width: $tabletVMax) {
            margin-bottom: 59px;
        }

        img {

            @media (max-width: $mobileResponsiveMax) {
                width: 246px;
            }
        }
        
    }

    .text-block {
        text-align: center;
        padding: 0 20px;

        @media (max-width: $tabletVMax) {
            padding: 0;
        }

        
    }

    form {
        padding-top: 10px;

        @media (min-width: $tabletVMin ) {
            .form-col:nth-child(odd) {
                padding-right: 5px;
            }

            .form-col:nth-child(even) {
                padding-left: 5px;
            }
        }

        @media (min-width: $tabletH ) {
            .form-col:nth-child(odd) {
                padding-right: 10px;
            }

            .form-col:nth-child(even) {
                padding-left: 10px;
            }
        }
    }

    .button-row {
        text-align: center;
        margin-top: 25px;

        @media (max-width: $tabletVMax) {
            margin-top: 33px;
        }

        .btn {
            margin: auto;
            padding: 13px 44px;
            width: 340px;
            text-align: center;

            @media (max-width: $mobileResponsiveMax) {
                width: 100%;
            }
        }
    }
}