.footer {
    padding-top: 263px;
    padding-bottom: 60px;
    text-align: center;
    background-color: $color-footer-bg;
    color: white;
    z-index: z('footer');

    @media (max-width: $tabletVMax) {
        padding-top: 214px;
    }

    .container {
        @media (min-width: $desktop) {
            padding-left: 30px;
        }
    }

    .logo-block {
        margin: auto;
        margin-bottom: 33px;
        clear: both;

        img {
                width: 200px;
            }
    }

    .text-block {
        color: rgba(255,255,255,0.7);

        padding: 0px 0px;
        margin-bottom: 80px;

        @media (min-width: $tabletVMin) {
            padding: 0px 70px;
            margin-bottom: 42px;
        }

        p {
            @media (max-width: $tabletVMax) {
                line-height: 1.8;
            }
        }
    }

    .social-block {

        margin-bottom: 98px;

        @media (min-width: $tabletH) {
            margin-bottom: 82px;
        }
    }
    .footer-block{
        margin: auto;
        text-align: center;
        color: rgba(255,255,255,0.83);
        
        font-size: 14px;
        margin-top: 50px;
    }
    
    .copyright-block{
        margin: auto;
        text-align: center;
        color: rgba(255,255,255,0.83);
        
        font-size: 14px;

        @media (min-width: $tabletH) {
            font-size: 14px;
        }

        p {
            @media (max-width: $tabletVMax) {
                margin-top: 16px;
            }
        }

        .divider {
            display: inline-block;
            padding: 0;
            margin: 0 25px;
            width: 1px;
            height: 13px;
            background-color: rgba(255,255,255,0.33);
            position: relative;
            top: 1px;

            @media (max-width: $tabletVMax) {
                margin: 0 7px;
            }
        }

        a {
            white-space: nowrap;
            color: rgba(255,255,255,0.83);

            &:hover {
                color: rgba(255,255,255,1);
            }
        }
    }
}