#rtl {
    .section-contact-us {
        .container {

            @media (min-width: $desktop) {
                // padding-left: 30px;
                padding-right: 30px;
            }
        }
        
        form {

            @media (min-width: $tabletVMin ) {
                .form-col:nth-child(odd) {
                    // padding-right: 5px;
                    // padding-left: 5px;
                }

                .form-col:nth-child(even) {
                    // padding-left: 5px;
                    // padding-right: 5px;
                }
            }

            @media (min-width: $tabletH ) {
                .form-col:nth-child(odd) {
                    // padding-right: 10px;
                    // padding-left: 10px;
                }

                .form-col:nth-child(even) {
                    // padding-left: 10px;
                    // padding-right: 10px;
                }
            }
        }
    }
}