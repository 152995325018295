.header {
    background: transparent;
    border-color: transparent;
    padding-top: 55px;

    @media (max-width: $mobileResponsiveMax) {
        padding-top: 5px;
    }

    .navbar-nav {
        & > li {
            margin-left: 54px;

            @media (min-width: $tabletVMin) and (max-width: $tabletVMax) {
                margin-left: 40px;
            }

            & > a {
                &, &:focus {
                    color: $color-heading!important;
                    padding: 15px 0;
                }
                
                &:hover {
                    color: $color-theme!important;
                    
                }

                &.btn {
                    &, &:hover {
                        color: white;
                        padding: 15px 32px;
                    }
                    
                }
            }

            &.active {
                
                & > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $color-theme!important;
                        background-color: transparent;
                    }
                }
                
            }
        }
    }
}