.section-we-are {
    height: 600px;
    padding-top: 40px;
    position: relative;

    @media (max-width: $tabletVMax) {
        height: auto;
        padding-top: 29px;
    }

    .section-we-are--bg {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        background-image: url('../img/bg-we-are.svg');
        background-repeat: no-repeat;
        background-size: 2770px;
        background-position: 50% -130px;

        @media (max-width: $tabletVMax) {
            background-image: none;
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .row {
        @media (max-width: $mobileResponsiveMax) {
            display: flex;
            flex-direction: column-reverse;
        }

        @media (min-width: $tabletH) {
            display: flex;
        }
    }

    .text-block {
        padding-top: 60px;

        @media (min-width: $tabletH) {
            padding-left: 15px;
        }

        @media (max-width: $mobileResponsiveMax) {
            text-align: center;
            position: relative;
            z-index: 1;
            top: -217px;
            margin-bottom: -217px;
        }
    }

    .button-row {

        @media (min-width: $tabletVMin) {
            padding-top: 35px;
            text-align: center;
            margin-bottom: 35px;
        }

        @media (min-width: $tabletH) {
            padding-top: 65px;
            text-align: left;
        }

        .btn {
            display: inline-block; 

            & + .btn {
                margin-left: 36px;
            }
        }

        @media (max-width: $mobileResponsiveMax) {
            display: flex;
            flex-direction: column;
            padding-top: 19px;

            .btn {
                display: block; 
                width: 100%;
    
                & + .btn {
                    margin-left: 0px;
                    margin-top: 6px;
                    font-size: 14px;
                }
            }
        }
    }

    .img-block {
        text-align: right;

        img {
            max-width: 100%;

        }

        @media (max-width: $tabletVMax) {
            text-align: center;
        }
    }
}