.section-order {
    padding-top: 140px;
    padding-bottom: 170px;
    background-color: $color-theme-bg;

    @media (max-width: $tabletVMax) {
        padding-top: 58px;
        padding-bottom: 87px;
    }

    .container {
        position: relative;

        &:after {
            display: block;
            content: '';
            width: 156px;
            height: 143px;
            position: absolute;

            top: -59px;
            left: 195px;

            background-image: url('../img/img-fruits.svg');
            background-position: 0 0;
            background-size: contain;
            background-repeat: no-repeat;

            @media (max-width: $tabletVMax) {
                width: 100px;
                top: -30px;
                right: 15px;
                left: auto;
            }

        }

        .row {
            @media (min-width: $tabletH) {
                display: flex;
            }
        }
        
    }

    .img-block {
        text-align: center;

        @media (min-width: $tabletH) {
            padding-left: 55px;
        }

        background-image: url('../img/bg-order.svg');
        background-repeat: no-repeat;

        @media (min-width: $tabletH) {
            background-size: $bg-shadow-tablet-H;
            background-position: 70% 66%;
        }
        
        @media (min-width: $desktop) {
            background-size: $bg-shadow-desktop;
        }

        @media (max-width: $tabletVMax) {
            padding-top: 40px;
            background-size: $bg-shadow-tablet-V;
            background-position: 50% 65%;

            img {
                max-width: 147px;
            }
        }
        @media (max-width: $mobileResponsiveMax) {
            padding-top: 40px;
            background-size: $bg-shadow-mobile;
            background-position: 50% 65%;

            img {
                max-width: 147px;
            }
        }

        @media (max-width: $mobileSmall) {
            background-size: $bg-shadow-mobile-small;
        }
    }
    .text-block {

        @media (min-width: $tabletH) {
            padding-left: 15px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    .button-row-app {

        margin-top: 30px;

        @media (max-width: $tabletVMax) {
            margin-top: 27px;
            text-align: center;
        }
    }
}