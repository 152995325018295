#rtl {
    .section-we-are {
        
        .section-we-are--bg {
            transform: scale(-1, 1);
        }

        .text-block {

            @media (min-width: $tabletH) {
                // padding-left: 15px;

                padding-left: 0;
                padding-right: 15px;
            }
        }

        .button-row {

            @media (min-width: $tabletH) {
                // text-align: left;
                text-align: right;
            }

            .btn {
                & + .btn {
                    // margin-left: 36px;

                    margin-left: 0;
                    margin-right: 36px;
                }
            }

            @media (max-width: $mobileResponsiveMax) {

                .btn {
                    & + .btn {
                        // margin-left: 0px;
                        margin-right: 0px;
                    }
                }
            }
        }

        .img-block {
            // text-align: right;
            text-align: left;
            transform: scale(-1, 1);

            @media (max-width: $tabletVMax) {
                text-align: center;
            }
        }
    }
}