.button-row-app {
    margin-top: 20px;

    .btn-apple {
        display: inline-block;
    }
    .btn-google {
        display: inline-block;
        margin-left: 25px;

        @media (max-width: $tabletVMax) {
            margin-left: 10px;
        }
    }
}