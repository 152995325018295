#rtl {
    form {

        // Home Page
        .form-block {

            &.form-block--select {
                // background-position: right 26px bottom 50%;
                background-position: left 26px bottom 50%;

                @media (max-width: $tabletVMax) {
                    // background-position: right 16px bottom 50%;
                    background-position: left 16px bottom 50%;
                }
            }

            textarea,
            select,
            input {
                &,
                &:focus,
                &:active {
                    // padding-left: 33px;
                    padding-right: 33px;

                    @media (max-width: $tabletVMax) {
                        // padding-left: 23px;
                        padding-right: 23px;
                    }
                }
            }
        }
        
        textarea {
            &,
            &.form-control,
            &:focus,
            &:active {
                // padding-left: 33px;
                padding-right: 33px;

                @media (max-width: $tabletVMax) {
                    // padding-left: 23px;
                    padding-right: 23px;
                }
            }
        }

        // Contact Us Page
        .form-group {
            input {
                &,
                &.form-control,
                &:focus,
                &:active {
                    // padding-left: 33px;
                    padding-right: 33px;
                    
                    @media (max-width: $tabletVMax) {
                        // padding-left: 23px;
                        padding-right: 23px;
                    }
                }
            }
        }
    }
}