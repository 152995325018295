#rtl {
    .button-row-app {

        .btn-apple {
        }
        .btn-google {
            // margin-left: 25px;
            margin-right: 25px;

            @media (max-width: $tabletVMax) {
                // margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}