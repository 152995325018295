body {
    font-family: $font;
    font-size: $font-size-p;
    color: $color-p;
    background-color: white;

    @media (max-width: $tabletVMax) {
        background-image: url('../img/bg-we-are.svg');
        background-repeat: no-repeat;
        background-position: 66% -670px;
        background-size: 2236px;
    }

    * {
        box-sizing: border-box;
    }
}

.container {
    @media (min-width: $desktop) {
        width: 1133px;
    }
}

.wrap>.container {
    padding: 0px 15px 0px;
}

p {
    font-size: $font-size-p;
    line-height: 1.625;

    @media (max-width: $tabletVMax) {
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
    }
}

h1 {
    font-size: $font-size-h1;
    color: $color-heading;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 26px;

    @media (max-width: $tabletVMax) {
        font-size: 28px;
        line-height: 1.5;
        margin-bottom: 17px;
        text-align: center;
    }
}

h2 {
    font-size: $font-size-h2;
    color: $color-heading;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 15px;

    @media (max-width: $tabletVMax) {
        font-size: 28px;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 20px;
    }
}

h3 {
    font-size: $font-size-h3;
    color: $color-heading;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 9px;

    @media (max-width: $tabletVMax) {
        font-size: 21px;
        line-height: 1.5;
        font-weight: 500;
        text-align: center;
    }
}

.colored {
    color: $color-theme;
}

.mobile-only {
    @media (min-width: $tabletH) {
        display: none!important;
    }    
}
.desktop-only {
    @media (max-width: $tabletH - 1) {
        display: none!important;
    }    
}
