#rtl {
    .header .navbar-nav {
        .dropdown{


            @media (min-width: $tabletVMin) {
                // margin-left: 60px;
                margin-left: auto;
                margin-right: 60px;
            }
            @media (min-width: $tabletH) {
                // margin-left: 88px;
                margin-left: auto;
                margin-right: 88px;
            }
            @media (min-width: $desktop) {
                // margin-left: 88px;
                margin-left: auto;
                margin-right: 88px;
            }

            .flag-icon {
                // left: -34px;
                left: auto;
                right: -34px;

                @media (max-width: $tabletVMax) {
                    // left: -24px;
                    left: auto;
                    right: -24px;
                }

                @media (max-width: $mobileResponsiveMax) {
                    // margin-right: 5px;
                    margin-right: 0px;
                    margin-left: 5px;
                }
            }
    
            &.open {
                .dropdown-menu {
                    text-align: left;

                    right: 0;
                    left: auto;

                    @media (max-width: $mobileResponsiveMax) {
                        text-align: right;
                    }
    
                }
            }
        }
    }
}