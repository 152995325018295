#rtl {
    .header {

        .navbar-header {

            @media (min-width: $tabletVMin) {
                float: right;
            }

            .navbar-brand {
                float: right;
            }
        }

        .navbar-nav {
            // float: right !important;
            // margin-right: -15px;

            @media (min-width: $tabletVMin) {
                float: left !important;
                margin-right: 0px;
                margin-left: -15px;
            }

            & > li {
                // margin-left: 54px;

                margin-left: 0;
                margin-right: 54px;
                float: right;

                @media (min-width: $tabletVMin) and (max-width: $tabletVMax) {
                    // margin-left: 40px;

                    margin-left: 0;
                    margin-right: 40px;
                }
            }
        }
    }
}