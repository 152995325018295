@media (max-width: $mobileResponsiveMax) {
    .navbar-header{
        .navbar-toggle {

            &,
            &:hover {
                background: transparent;
                border: 0;

                position: relative;

                &:after {
                    display: block;
                    content: "";
                    width: 20px;
                    height: 20px;
                    background-image: url('../img/menu-toggle.svg');
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: 100% 0;
                    position: absolute;
                    z-index: 1;
                    top: 8px;
                    right: 0;
                }
            }
        }

        & + .navbar-collapse {
            border: none;

            .navbar-nav{
                & > li {
                    
                    margin-left: 0;
                    padding-left: 25px;
                    padding-right: 25px;

                    & > a {

                    }
                }
            }
        }
    }
}
