.footer {
    .social-block {
        margin: auto;
        text-align: center;

        a {
            display: inline-block;
            border-radius: 50%;
            
            vertical-align: middle;
            cursor: pointer;
            background-color: rgba(255,255,255,0.1);

            width: 68px;
            height: 68px;
            line-height: 68px;
            margin: 0 8px;

            @media (max-width: $tabletVMax) {
                width: 56px;
                height: 56px;
                line-height: 56px;
                margin: 0 8px;

                img {
                    max-width: 23px;
                    max-height: 23px;
                }
            }

            @media (max-width: $mobileSmall) {
                width: 46px;
                height: 46px;
                line-height: 46px;

                img {
                    max-width: 18px;
                    max-height: 18px;
                }
            }

            &:hover {
                background-color: rgba(255,255,255,0.33);
            }
        }
    }
}