.btn {
    display: block;
    padding: 15px 44px;
    font-family: $font;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.56;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
    text-align: center;

    &:active,
    &:focus {
        outline: none!important;
        box-shadow: none!important;
    }

    &.btn-theme {
        border-radius: 27px;
        background-image: linear-gradient(122deg, #ffab3d 24%, #e65400 115%);
        padding: 15px 44px;
        text-align: center;

        @media (max-width: $tabletVMax) {
            padding: 11px 44px;
        }

        &:hover {
            color: white;
        }
    }

    &.btn-transparent {
        color: $color-btn-transparent;
        padding-left: 0;
        padding-right: 0;
    }
}
.btn-apple {
    display: block;
    width: 164px;
    height: 54px;
    background-image: url('../img/btn-app@2x.png');
    background-size: contain;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;

    @media (max-width: $tabletVMax) {
        width: 128px;
        height: 40px;
        background-image: url('../img/btn-apple-mobile@2x.png');
    }
}
.btn-google {
    display: block;
    width: 178px;
    height: 54px;
    background-image: url('../img/btn-google@2x.png');
    background-size: contain;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;

    @media (max-width: $tabletVMax) {
        width: 128px;
        height: 40px;
        background-image: url('../img/btn-google-mobile@2x.png');
    }
}