#rtl {
    .section-download-now {
        .container {
            @media (min-width: $desktop) {
                // padding-left: 30px;
                padding-right: 30px;
            }

            .row {
                @media (min-width: $tabletH) {
                // @media (min-width: $desktop) {
                    // display: flex;
                }
            }
        }

        .img-block {

            @media (min-width: $tabletH) {
                // padding-right: 70px;
                padding-left: 70px;
            }
        }

        .text-block {

            @media (min-width: $tabletH) {
                // margin-left: -24px;
                // margin-right: 34px;
                margin-right: -24px;
                margin-left: 34px;
            }
        }

        .button-row-app {
           
            .btn-apple {
                @media (min-width: $tabletH) {

                }
            }

            .btn-google {
                @media (min-width: $tabletH) {
                    // margin-left: 16px;
                    margin-right: 16px;
                }
            }
        }
        
    }
}