@media (max-width: $mobileResponsiveMax) {
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
        margin-left: 0px;
        margin-top: -5px;
    }
}
// Logo Vertical Tablet position
@media (min-width: $tabletVMin) {
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
        margin-left: 0px;
        margin-top: -15px;
    }
}

@media (min-width: $tabletH) {
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
        margin-left: 0px;
        margin-top: -25px;
    }
}

// Logo Desktop position
.navbar-brand > img {
    width: 70px;
}

@media (max-width: $tabletVMax) {
    .navbar-brand > img {
        width: 52px;
    }
}