form {

    // Home Page
    .form-block {
        margin-bottom: 20px;
        border: 1px solid $color-border;
        border-radius: 33px;
        overflow: hidden;
        background: #fff;

        height: $input-height;

        @media (max-width: $tabletVMax) {
            height: $input-height-mobile;
        }

        @media (max-width: $tabletVMax) {
            margin-bottom: 11px;
            border-radius: 25px;
        }

        &.form-block--select {
            background-image: url('../img/dropdown.svg');
            background-repeat: no-repeat;
            background-position: right 26px bottom 50%;
            background-size: 20px;

            @media (max-width: $tabletVMax) {
                background-position: right 16px bottom 50%;
                background-size: 18px;
            }
        }

        select {
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
        }
        
        /* For IE10 */
        select::-ms-expand {
        display: none;
        }

        textarea,
        select,
        input {
            &,
            &:focus,
            &:active {
                border: 0;
                outline: none;
                width: 100%;
                padding-left: 33px;
                font-size: 16px;
                color: $color-p;
                line-height: 1.625;
                background-color: transparent;
                box-shadow: none;

                @media (max-width: $tabletVMax) {
                    padding-left: 23px;
                    font-size: 12px;
                }
            }
        }

        input {
            height: $input-height;

            @media (max-width: $tabletVMax) {
                height: $input-height-mobile;
            }
        }

        select {
            height: $select-height;

            @media (max-width: $tabletVMax) {
                height: $select-height-mobile;
            }
        }

    }

    
    textarea {
        &,
        &.form-control,
        &:focus,
        &:active {
            margin-bottom: 20px;
            border: 1px solid $color-border;
            border-radius: 33px;
            overflow: hidden;
            background: #fff;
            height: $textarea-height;
            padding-top: 15px;
            outline: none;
            width: 100%;
            padding-left: 33px;
            font-size: 16px;
            color: $color-p;
            line-height: 1.625;
            box-shadow: none;

            @media (max-width: $tabletVMax) {
                padding-left: 23px;
                font-size: 12px;
            }

            @media (max-width: $tabletVMax) {
                height: $textarea-height-mobile;
            }
        }

        
    }

    // Contact Us Page
    .form-group {
        input {
            &,
            &.form-control,
            &:focus,
            &:active {
                margin-bottom: 20px;
                border: 1px solid $color-border;
                border-radius: 33px;
                overflow: hidden;
                background: #fff;
                height: $input-height;
                // padding-top: 15px;
                // border: 0;
                outline: none;
                width: 100%;
                padding-left: 33px;
                font-size: 16px;
                color: $color-p;
                line-height: 1.625;
                // background-color: transparent;
                box-shadow: none;

                @media (max-width: $tabletVMax) {
                    padding-left: 23px;
                    font-size: 12px;
                }

                @media (max-width: $tabletVMax) {
                    height: $input-height-mobile;
                }
            }
        }
    }

    // Contact page with ERROR
    .has-error {
        textarea,
        input {
            &,
            &.form-control,
            &:focus,
            &:active {
                color: $color-error;
                border-color: $color-error;

                 @include input-placeholder {
                    color: $color-error;
                 }
            }
        }
    }
}


