.section-download-now {
    position: relative;
    z-index: z('download-section'); 

    top: -223px;
    margin-bottom: -375px;
    overflow: hidden;

    @media (max-width: $tabletVMax) {
        // top: -90px;
        // top: -192px;
        top: -243px;
        overflow: visible;
    }

    .container {
        @media (min-width: $desktop) {
            padding-left: 30px;
        }

        .row {
            @media (min-width: $tabletH) {
            // @media (min-width: $desktop) {
                display: flex;
            }
        }
    }

    .inner-block {
        background-color: #fff;

        border-radius: 64px;
        padding-top: 73px;
        padding-bottom: 71px;
        
        @media (max-width: $tabletVMax) {
            border-radius: 28px;
            padding-top: 73px;
            padding-bottom: 35px;
        }
    }

    .section--logo {
        float: none;
            width: auto;
        @media (min-width: $tabletH) {
            float: left;
            width: 42%;
        }
    }
    
    .section--button {
        float: none;
        width: auto;

        @media (min-width: $tabletH) {
            float: left;
            width: auto;
        }
    }
    .img-block {
        text-align: center;

        @media (min-width: $tabletH) {
            padding-right: 70px;
        }

        @media (max-width: $tabletVMax) {
            position: relative;
            top: -106px;
            margin-bottom: -102px;

            img {
                width: 102px;
            }
        }
    }

    .text-block {

        @media (min-width: $tabletH) {
            margin-left: -24px;
            margin-right: 34px;
        }

        h3 {
            margin-top: 12px;
        }
        p {
            margin-bottom: 15px;
            color: $color-download-p;
            
            @media (max-width: $tabletVMax) {
                font-size: 12px;
            }
        }
    }

    .button-row-app {
        margin-top: 30px;

        @media (max-width: $tabletVMax) {
            text-align: center;
            margin-top: 28px;
        }

        .btn-apple {

            @media (min-width: $tabletH) {
                width: 204px;
                height: 65px;
            }
        }

        .btn-google {

            @media (min-width: $tabletH) {
                width: 215px;
                height: 65px;
                margin-left: 16px;
            }
        }
    }
    
}