.section-browse {
    padding-top: 205px;
    padding-bottom: 150px;

    @media (max-width: $tabletH) {
        padding-top: 81px;
        padding-bottom: 74px;
    }

    .container {
        position: relative;

        &:after {
            display: block;
            content: '';
            width: 156px;
            height: 143px;
            position: absolute;

            top: -55px;
            right: 16px;

            background-image: url('../img/img-fruits.svg');
            background-position: 0 0;
            background-size: contain;
            background-repeat: no-repeat;

            @media (max-width: $mobileResponsiveMax) {
                width: 100px;
                top: -30px;
                right: 15px;
            }

        }
    }

    .row {
        @media (max-width: $mobileResponsiveMax) {
            display: flex;
            flex-direction: column-reverse;
        }

        @media (min-width: $tabletH) {
            display: flex;
        }
    }

    .img-block {
        text-align: center;

        @media (min-width: $tabletH) {
            padding-right: 20px;
        }

        background-image: url('../img/bg-browse.svg');
        background-repeat: no-repeat;

        @media (min-width: $tabletH) {
            background-size: $bg-shadow-tablet-H;
            background-position: 30% 50%;
        }

        @media (min-width: $desktop) {
            background-size: $bg-shadow-desktop;
        }

        @media (max-width: $tabletVMax) {
            padding-top: 40px;
            background-size: $bg-shadow-tablet-V;
            background-position: 50% 60%;

            img {
                max-width: 145px;
            }
        }

        @media (max-width: $mobileResponsiveMax) {
            padding-top: 40px;
            background-size: $bg-shadow-mobile;
            background-position: 30% 60%;

            img {
                max-width: 145px;
            }
        }
        
        @media (max-width: $mobileSmall) {
            background-size: $bg-shadow-mobile-small;
        }
    }

    .text-block {

        @media (min-width: $tabletH) {
            margin-left: -30px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    .button-row-app {

        margin-top: 30px;

        @media (max-width: $tabletVMax) {
            margin-top: 26px;
            text-align: center;
        }
    }
    
}