.header .navbar-nav {

    .dropdown{

        @media (min-width: $tabletVMin) {
            margin-left: 60px;
        }
        @media (min-width: $tabletH) {
            margin-left: 88px;
        }
        @media (min-width: $desktop) {
            margin-left: 88px;
        }

        .flag-icon {
            width: 30px;
            height: 30px;
            background-color: transparent;
            display: block;
            position: absolute;
            left: -34px;
            top: 10px;

            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;


            @media (max-width: $tabletVMax) {
                width: 20px;
                left: -24px;
            }

            @media (max-width: $mobileResponsiveMax) {
                position: static;
                display: inline-block;
                width: 30px;
                vertical-align: middle;
                margin-right: 5px;
            }

            &.flag-icon--sa {
                background-image: url('../img/flag-sa.svg');
            }
            &.flag-icon--uk {
                background-image: url('../img/flag-uk.svg');
            }
        }

        & {
            .dropdown-toggle{
                background: transparent !important;
            }
        }
        &.open {
            .dropdown-toggle{
                color: $color-theme !important;
                background: transparent !important;

                @media (max-width: $mobileResponsiveMax) {
                    padding-bottom: 0;
                }
            }

            .dropdown-menu {
                border: none;
                text-align: right;
                background: transparent!important;
                box-shadow: none;

                width: auto;
                min-width: 0;

                bottom: -20px;
                top: auto;

                right: auto;
                left: 0;

                @media (max-width: $mobileResponsiveMax) {
                    text-align: left;
                    bottom: 0;
                }

                a {
                    padding-right: 0px;
                    padding-left: 0;
                    background-color: transparent;
                    position: relative;
                    outline: none!important;

                    .flag-icon {
                        top: -2px;
                    }
                }
            }
        }

    }
    
}