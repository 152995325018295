#rtl {
    .section-order {
        .container {
        
            &:after {
                // left: 195px;
                left: auto;
                right: 195px;

                transform: scale(-1, 1);

                @media (max-width: $tabletVMax) {
                    // right: 15px;
                    // left: auto;

                    left: 15px;
                    right: auto;
                }

            }
        }

        .img-block {

            @media (min-width: $tabletH) {
                // padding-left: 55px;

                // padding-left: 0px;
                padding-right: 55px;
            }

            @media (min-width: $tabletH) {
                // background-position: 70% 66%;
                background-position: 30% 66%;
            }

            @media (max-width: $tabletVMax) {
                // background-position: 50% 65%;
            }

            @media (max-width: $mobileResponsiveMax) {
                // background-position: 50% 65%;
            }
        }

        .text-block {

            @media (min-width: $tabletH) {
                // padding-left: 15px;
                padding-left: 0px;
                padding-right: 15px;
            }
        }
    }
}